<template>
  <div>
    <h2 class="text-center" style="margin-bottom: 30px;">請先加入好友</h2>
    <h5 class="text-center" style="margin-bottom: 30px;">您尚未加入「{{organization.name}}」<br>請先加入好友才能使用本功能喔！</h5>
    <a :href="lineOaUrl" class="btn btn-block primary-button btn-lg" target="_blank">加入好友</a>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState('liffCustomerBind', {
      organization: state => state.organization,
      lineOaId: state => state.lineOaId,
    }),
    lineOaUrl () {
      return 'https://page.line.me/?accountId=' + this.lineOaId;
    },
  }
}
</script>
